import { Component , cloneElement, toChildArray, render, createRef} from "preact";
import {createPortal} from "preact/compat";
import { connect } from 'react-redux';

import ResizeCard from "../resize-card";
import withThumbnailContent from "../thumbnail-index-generator";

import * as helpers from "@cargo/common/helpers";
import { generateColumnMap } from "../layout-helpers";
import { diffProps } from '../../../../diffProps'

import { withPageInfo } from "../../page-info-context";
import windowInfo from "@cargo/common/window-info"

import GridEditor from '../../../overlay/grid-editor';

import _ from 'lodash';
import register from "../../register"

const layoutData = {
	name: 'grid',
	displayName: 'Grid',
	tagName: 'GALLERY-GRID',
	disabledMediaItemOptions: ['scale', 'limit-by'],
	mediaItemOptions: [
		{
			labelName: "Spans",
			name: "grid-span",
			type: "scrubber",
			allowUnclampedTextEntry: false,			
			value: 1,
			min: 1,
			max: 12,
			step: 1,
			numberOnlyMode: true,
		},
		{
			labelName: "Vertical Align",
			name: "grid-vertical-align",
			type: "radio",
			value: "inherit",
			inheritsFrom: "vertical-align",
			values: [
				{
					labelName: 'Top',
					iconName: 'alignContentTop',
					value: 'top'
				},
				{
					labelName: 'Middle',
					iconName: 'alignContentMiddle',
					value: 'center'
				},
				{
					labelName: 'Bottom',
					iconName: 'alignContentBottom',
					value: 'bottom'
				},
			]
		},
	],		
	options: [
		{
			labelName: "Columns",
			name: "columns",
			type: "scrubber",
			allowUnclampedTextEntry: false,			
			value: 3,
			min: 1,
			max: 12,
			step: 1,
			numberOnlyMode: true,
		},
		{
		 	labelName: "Gutter",
		 	name: "gutter",
		 	type: "composite-scrubber",
		 	addDefaultUnitToUnitlessNumber: true,
		 	value: '1rem',
		 	defaultUnit: 'rem',
		 	min: 0,
		 	max: {
		 		vw: 30,
		 		vh: 30,
		 		vmax: 30,
		 		vmin: 30,
				rem: 20,
				em: 20,
				px: 300,
				etc: 100,
		 	},
		 	step: {px: 1, rem: 0.1, em: 0.1, '%': 0.1, vw: 0.1, vmin: 0.1, vmax: 0.1, vh: 0.1, etc: 1},
		 	allowCalc: true,
		 	allowedUnits: ['px', '%', 'rem', 'vmin', 'vmax', 'vw', 'vh'],
		},
		{
			labelName: "Vertical Align",
			name: "vertical-align",
			type: "radio",
			value: "top",
			values: [
				{
					labelName: 'Top',
					iconName: 'alignContentTop',
					value: 'top'
				},
				{
					labelName: 'Middle',
					iconName: 'alignContentMiddle',
					value: 'center'
				},
				{
					labelName: 'Bottom',
					iconName: 'alignContentBottom',
					value: 'bottom'
				},
			],
		},
		{
			labelName: "Horizontal Align",
			name: "horizontal-align",
			type: "radio",
			value: "left",		
			values: [
				{
					labelName: 'Left',
					iconName: 'alignContentLeft',
					value: 'left'
				},
				{
					labelName: 'Center',
					iconName: 'alignContentCenter',
					value: 'center'
				},
				{
					labelName: 'Right',
					iconName: 'alignContentRight',
					value: 'right'
				},
			],
		},			 
	],
	mobileOptions: [
		{
			labelName: "Columns",
			name: "mobile-columns",
			allowUnclampedTextEntry: false,			
			type: "scrubber",
			value: 3,
			min: 1,
			max: 12,
			step: 1,
			numberOnlyMode: true,
		},
		{
		 	labelName: "Gutter",
		 	name: "mobile-gutter",
		 	type: "composite-scrubber",
		 	addDefaultUnitToUnitlessNumber: true,
		 	value: '1rem',
		 	defaultUnit: 'rem',
		 	min: 0,
		 	max: {
				rem: 15,
				em: 20,
				px: 100,
				etc: 10,
		 	},
		 	allowCalc: true,
		 	allowedUnits: ['px', '%', 'rem', 'vmin', 'vmax', 'vw', 'vh'],
		},
		{
			labelName: "Vertical Align",
			name: "mobile-vertical-align",
			type: "radio",
			value: "top",
			values: [
				{
					labelName: 'Top',
					iconName: 'alignContentTop',
					value: 'top'
				},
				{
					labelName: 'Middle',
					iconName: 'alignContentMiddle',
					value: 'center'
				},
				{
					labelName: 'Bottom',
					iconName: 'alignContentBottom',
					value: 'bottom'
				},
			],
		},
		{
			labelName: "Horizontal Align",
			name: "mobile-horizontal-align",
			type: "radio",
			value: "left",		
			values: [
				{
					labelName: 'Left',
					iconName: 'alignContentLeft',
					value: 'left'
				},
				{
					labelName: 'Center',
					iconName: 'alignContentCenter',
					value: 'center'
				},
				{
					labelName: 'Right',
					iconName: 'alignContentRight',
					value: 'right'
				},
			],
		},
	]	
}

layoutData.mediaItemDefaults = helpers.collapseOptions(layoutData.mediaItemOptions);
layoutData.defaults = helpers.collapseOptions(layoutData.options);
layoutData.mobileDefaults = helpers.collapseOptions(layoutData.mobileOptions);

class Grid extends Component {

	constructor(props){
		super(props);

		this.state = {
			isMobile: windowInfo.data.mobile.active,
			sizeIncrement: 0,
			elWidth: 1000,
			gutterWidth: 0,
			gutterHeight: 0,
			hasSizes:false,
			renderLimit: 250
		};

		this.uid = _.uniqueId();

		this.paginationWatcherRef = createRef();

		this.paginationObserver = new IntersectionObserver(this.requestPagination, {
			root: this.props.scrollContext.scrollingElement === window ? document : this.props.scrollContext.scrollingElement,
			rootMargin: (screen.height * 2) + 'px',
			threshold: [0,1]
		});

	}

	requestPagination = async entries => {

		// store latest intersection result
		this.isIntersecting = entries[0].isIntersecting;

		if(
			// do nothing if a pagination loop is already running
			this.isPaginating
			// or if we already hit the limit
			|| this.state.renderLimit >= this.getMediaItems().length
		) {
			return;
		}

		this.isPaginating = true;

		// keep going while the observer is within the pagination boundary
		while(this.isIntersecting) {

			// increase render limit by 100 items
			let newRenderLimit = this.state.renderLimit + 100;

			this.setState({
				renderLimit: newRenderLimit
			});

			if(newRenderLimit >= this.getMediaItems().length) {
				// we've reached the limit. Don't do anything
				break;
			}

			// wait a beat before continuing
			await new Promise(resolve => setTimeout(resolve, 250));

		}

		// The pagination loop has completed and we are 
		// ready to start a new one if needed.
		this.isPaginating = false;

	}

	getMediaItems() {
		return Array.from(this.props.baseNode.children).filter(node => node.nodeName =='MEDIA-ITEM');
	}

	render(props, state){

		const {
			adminMode,
			baseNode,
			pageInfo,
		} = props;

		const {
			isMobile,
			gutterWidth,
			gutterHeight,
			hasSizes
		} = state;

		let {
			elWidth
		} = state;

		if( !hasSizes){

			elWidth = this.props.baseNode.offsetWidth;
		}


		let verticalAlign = (this.state.isMobile && this.props['mobile-vertical-align'] !== undefined ) ? this.props['mobile-vertical-align'] : this.props['vertical-align'];

		if (this.props.verticalAlign) {
			verticalAlign = this.props.verticalAlign;
		}

		let columns = (this.state.isMobile && this.props['mobile-columns'] !== undefined ) ? this.props['mobile-columns'] : this.props.columns;		
		columns = Math.max(1, parseInt(columns)) || 1;

		let gutter = (this.state.isMobile && this.props['mobile-gutter'] !== undefined ) ? this.props['mobile-gutter'] : this.props.gutter;
		let gutterArr = gutter.split(' ');

		let horizontalGutter = gutterArr.length > 1 ? gutterArr[0] : gutterArr[0];
		horizontalGutter = helpers.getCSSValueAndUnit(horizontalGutter, 'rem');

		if ( horizontalGutter[1] =='rem' && isMobile ) {
			horizontalGutter = ['', 'calc( var(--mobile-padding-offset, 1) * ' + horizontalGutter.join('') + ' )'];
		}

		let verticalGutter = gutterArr.length > 1 ? gutterArr[1] : gutterArr[0];
		verticalGutter = helpers.getCSSValueAndUnit(verticalGutter, 'rem');		

		if ( verticalGutter[1] =='rem' && isMobile ) {
			verticalGutter = ['', 'calc( var(--mobile-padding-offset, 1) * ' + verticalGutter.join('') + ' )'];
		}

		let {
			columnAndGutterMap,
			columnWidth,
			gutterPixelWidth,
		} = generateColumnMap(columns, gutterWidth, elWidth);

		const mediaItems = this.getMediaItems();

		const rowMap = [];
		let rowIndex = 0;
		let columnIndex = 0;

		const mobileColumnsAreInUse = isMobile && ( !isNaN(parseInt(this.props['mobile-columns'])) && parseInt(this.props['mobile-columns']) !== parseInt(this.props.columns));

		mediaItems.forEach((el, i) => {

			if(i > this.state.renderLimit) {
				return
			}

			if(!rowMap[rowIndex]){
				rowMap[rowIndex] = [];
			}

			// always force 
			let gridSpans = mobileColumnsAreInUse ? 1 : (parseInt(el.getAttribute('grid-span')) || 1);

			// if grid span goes over, cut it down
			if( (columnIndex%columns) + gridSpans > columns){
				gridSpans = columns - (columnIndex%columns)
			}

			let itemValign = el.getAttribute('grid-vertical-align') || null;

			rowMap[rowIndex].push({index: i, spans: gridSpans, hasOrphans: false, verticalAlign: itemValign, el: el});

			columnIndex = columnIndex+gridSpans;

			if(columnIndex%columns == 0){
				rowIndex++;
			}

			// this row has orphaned items
			if( columnIndex%columns != 0 && i == mediaItems.length-1){
				rowMap[rowMap.length-1].hasOrphans = true;
			}

		});


		const remainder = mediaItems.length%columns;
		const attributeMap = [];
		const shadowStyleMap = [];

		rowMap.forEach((row, rowIndex)=>{

			let rowSpans= _.sumBy(row, (mapItem)=>mapItem.spans);

			let accumulatedColumns = 0;
			let columnPixelMap = [];
			let tallestHeight = 0;
			let accumulatedWidth = 0;

			let itemStatusArray = row.map((mapItem, columnIndex)=>{

				const spanWidth = mapItem.spans;
				const size = mapItem.el._size || {
					width: 0,
					height: 0,
					mediaSize: {
						width: 0,
						height: 0,
						padSize: 0,
						horizPadSize: 0,
						vertPadSize: 0,
					},
					mediaItemSize: {
						width: 0,
						height: 0,
						padSize: 0,
						vertPadSize: 0,
						horizPadSize: 0,
					}					
				};


				let startColumn = accumulatedColumns;
				let endColumn = accumulatedColumns+spanWidth+-1;

				// use uniform column size to generate the initial pixel width to avoid getting different heights
				let gridItemPixelWidth = (columnWidth)*spanWidth + (gutterWidth*(spanWidth-1));

				// use un-rounded value to generate height
				let scaleDown = (gridItemPixelWidth + -size.mediaItemSize.horizPadSize + -size.mediaSize.horizPadSize)/(size.width) || 1;
				
				let gridItemPixelHeight = Math.round((size.height) * scaleDown + size.mediaSize.vertPadSize) || 0;

				// snap the actual width to the column and gutter map to make sure column alignments are consistent betweeen rows
				gridItemPixelWidth = columnAndGutterMap[endColumn].right - columnAndGutterMap[startColumn].left;

				accumulatedColumns+=spanWidth;
				tallestHeight = Math.max(tallestHeight, gridItemPixelHeight);

				let itemStatuses ={}

				let gridWidthStyle = '';

				if( gridItemPixelWidth< 0){
					gridItemPixelHeight =0;
					gutterPixelWidth = 0;
					gridWidthStyle+= `
					--grid-width: 0px;
					--grid-gutter-width: ${gridItemPixelWidth+gutterPixelWidth}px;`
				} else {
					gridWidthStyle += ` --grid-width: ${gridItemPixelWidth}px;`					
				}
				
				

				itemStatuses.orphanedItem = row.hasOrphans;

				if ( columnIndex === row.length-1){
					itemStatuses.lastColumn = true;

					if( mapItem.index < mediaItems.length-1){
						gridWidthStyle = ` --grid-width: ${elWidth - accumulatedWidth}px;`	
					}
					accumulatedWidth = 0;
				} else {
					if( columnIndex == 0 ){
						itemStatuses.firstColumn = true;
					}
					accumulatedWidth = accumulatedWidth+ gridItemPixelWidth+gutterWidth									
				}

				if (rowIndex == rowMap.length-1){
					itemStatuses.lastRow = true;
				}

				itemStatuses.gridItemPixelHeight = gridItemPixelHeight
				itemStatuses.gridItemPixelWidth = gridItemPixelWidth;
				itemStatuses.gridWidthStyle = gridWidthStyle;

				return itemStatuses;

			})

			var tallestItem = _.maxBy(itemStatusArray, (itemStatuses)=>itemStatuses.gridItemPixelHeight);
			tallestHeight = tallestItem.gridItemPixelHeight;

			row.forEach((mapItem, columnIndex)=>{

				let itemStatuses = itemStatusArray[columnIndex];

				let marginTop = 0;
				let vAlign = verticalAlign;

				switch(mapItem.verticalAlign) {
					case 'top':
					case 'center':
					case 'bottom':
						vAlign = mapItem.verticalAlign;
						break;
				}

				switch(vAlign) {
					case 'top':
						marginTop = 0;
						break;
					case 'center':
						marginTop = (tallestHeight - itemStatuses.gridItemPixelHeight)*.5
						break;
					case 'bottom':
						marginTop = tallestHeight - itemStatuses.gridItemPixelHeight;	
						break;
				}				

				let style = `margin-top: ${marginTop}px; --frame-padding: ${itemStatuses.gridItemPixelHeight}px; ${itemStatuses.gridWidthStyle}`;

				let slotRow = rowIndex == 0 ? ' first-row' : itemStatuses.lastRow ? ' last-row' : '';
				let slotColumn = itemStatuses.firstColumn ? ' first-in-row' : itemStatuses.lastColumn ? ' last-in-row' : '';
				const slot = 'row-'+rowIndex+'-'+mapItem.index + slotRow + slotColumn;
				attributeMap.push({
					slot: slot,
					itemResize: this.onItemResize,
					'limit-by': null,
				});

				shadowStyleMap.push({
					slot: slot,
					style: style,
					itemStatuses
				})

			});

		});

		mediaItems.forEach((el, index)=>{
			const attribs = attributeMap[index] || {
				slot: null
			}
			const props = el._props || {}
			diffProps(el, {...props, ...attribs}, props, false, false)
		})

		const shadowMap = [];

		rowMap.forEach((row, index)=>{
			shadowMap.push(
				<div key={'row-'+index} part={index == 0 ? 'first-row' : index === rowMap.length-1 ? 'last-row' : 'row'} className={`row ${row.hasOrphans ? 'has-orphans' : ''}`}>
					{row.map((mapItem)=> {
						return <slot name={attributeMap[mapItem.index].slot} key={'slot-'+index+'-'+mapItem.index}></slot>
					})}
				</div>
			)
		})

		return <>
			{this.props.children}
			{createPortal(<>
					<style>{`
						:host {
							display: block;
							width: var(--resize-parent-width, 100%);
							max-width: 100%;
							position: relative;							
			 				--grid-gutter-width: ${gutterPixelWidth}px;
							--grid-gutter-height: ${gutterHeight}px;
							--grid-columns: ${columns};
							margin-top: calc(var(--gutter-expand, 0) * ${gutterHeight}px );	
						}				

						* {
							box-sizing: border-box;
						}

						${shadowStyleMap.map((mapItem, index)=>{
							return `::slotted(media-item[slot="${mapItem.slot}"]) {
								${mapItem.style}
								${mapItem.itemStatuses.lastColumn && !mapItem.itemStatuses.orphanedItem ? `flex-grow: 1; --object-fit: fill;` : ''}
								${mapItem.itemStatuses.lastColumn ? '': 'margin-right: var(--grid-gutter-width);'}
								${mapItem.itemStatuses.lastRow ? '': 'margin-bottom: var(--grid-gutter-height);'}
							} `
						}).join('')}
								

						.row {
							max-width: ${elWidth}px;
						    align-items: flex-start;
						    justify-content: flex-start;
							position: relative;
							display: flex;
							flex-direction: row;
							flex-wrap: nowrap;					
							width: 100%;
							clear: both;
						}				
		
						.row.has-orphans {
						    justify-content: flex-start;
						}

						${isMobile && props['mobile-horizontal-align'] ? `
							:host([mobile-horizontal-align="left"]) .row.has-orphans {
								justify-content: flex-start;
							}
			
							:host([mobile-horizontal-align="center"]) .row.has-orphans {
								justify-content: center;
							}
			
							:host([mobile-horizontal-align="right"]) .row.has-orphans {
								justify-content: flex-end;
							}
						`:`
							:host([horizontal-align="left"]) .row.has-orphans {
								justify-content: flex-start;
							}
			
							:host([horizontal-align="center"]) .row.has-orphans {
								justify-content: center;
							}
			
							:host([horizontal-align="right"]) .row.has-orphans {
								justify-content: flex-end;
							}
						`}
		
						:host([hidden]) {
							display: none;
						}
		
						::slotted(media-item) {
							${adminMode ? 'pointer-events: auto;' : ''}
							position: relative;
						    display: flex;
						    max-width: 100%;   
						    flex-grow: 0;
						    flex-shrink: 0;
						    flex-basis: var(--grid-width);
						    width: var(--grid-width);
						}
		

		
					`}</style>
					<ResizeCard
						values={{
							elWidth: '100%',
							gutterWidth: horizontalGutter.join(''),
							gutterHeight: verticalGutter.join('')
						}}
						onResize={this.onResize}
					/>
					{adminMode && pageInfo.isEditing && !helpers.isServer && <GridEditor
							{...props}
							disableResize={mobileColumnsAreInUse}
							gallerySpecificAttributes={['grid-span', 'grid-vertical-align']}
							galleryInstance={baseNode}
							galleryComponent={this}
							columnAndGutterMap={columnAndGutterMap}
						/>
					}
					{shadowMap}
					<div ref={this.paginationWatcherRef} class='pagination-watcher'></div>
				</>, this.props.baseNode.shadowRoot)}
			</>
	}

	onMobileChange = (isMobile)=>{
		this.setState({isMobile})
	}

	componentDidMount(){
		
		windowInfo.on('mobile-change', this.onMobileChange);

		if(this.paginationObserver && this.paginationWatcherRef.current) {
			this.paginationObserver.observe(this.paginationWatcherRef.current);
		}

	}


	componentWillUnmount(){

		windowInfo.off('mobile-change', this.onMobileChange)

		if(this.paginationObserver && this.paginationWatcherRef.current) {
			this.paginationObserver.unobserve(this.paginationWatcherRef.current);
		}

	}


	onItemResize = (element)=>{

		this.setState((prevState)=>{
			return {
				sizeIncrement: prevState.sizeIncrement+1
			}
		})
	}
	
	onResize = (key, size) =>{

		this.setState((prevState)=>{

			const newState = {...prevState};
			newState[key] = size;
			newState.hasSizes = true;
			return newState
		})
	}

}

Grid.defaultProps = {
	'show-tags':true,
	'show-title':true,

	columns: layoutData.defaults['columns'].value,
	// gutter: layoutData.defaults['gutter'].value,
	// gutter default changed from 2rem to 1rem  - set default here
	// make sure behavior stays the same when there is no gutter assigned

	gutter: '2rem',
	'vertical-align': layoutData.defaults['vertical-align'].value,
	'horizontal-align': layoutData.defaults['horizontal-align'].value,
}

const ConnectedGrid = withPageInfo(withThumbnailContent(connect(
    (state, ownProps) => {
        return {
            adminMode       : state.frontendState.adminMode,
        };
    }
)(Grid)))

register(ConnectedGrid, 'gallery-grid', [
	// tag attributes
	'thumbnail-index',
	'thumbnail-index-metadata',
	'links-filter-index',
	'show-tags',
	'show-title',

	'columns',
	'gutter',
	'mobile-columns',
	'mobile-gutter',
	'vertical-align',
	'horizontal-align',
	'mobile-horizontal-align',
	'mobile-vertical-align'
]) 

export {layoutData, ConnectedGrid};
export default Grid




