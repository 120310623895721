import { Component } from "preact";
import { matchPath } from "react-router";
import { bindActionCreators } from 'redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as helpers from "@cargo/common/helpers";
import { actions } from "./actions";
import withStore from './withStore';
import selectors from "./selectors"
import PinManager, { PinManagerContext } from "./components/pin-context";
import Content from "./components/content";
import SiteImages from "./components/site-images"
import DigitalProductDownloadForm from "./components/digital-product-download"
import _ from 'lodash';
import { treeWalker } from "./helpers";
// import { smoothScroll } from "@cargo/common/smooth-scroll";

export const paths = {
	ROOT_PATH: '/',
	IMG_PATH: '/siteimages',
	SITE_PREVIEW_PROXY_PATH: '/site.preview/*',
	ADMIN_CLIENT_SIDE_RENDERING_PATH: '/client-side-rendering.html',
	PAGE_PATH: '/:page',
	PAGE_BY_ID_PATH: '/pid/:pid/:args?',
	DIGITAL_PRODUCT_DOWNLOAD: '/filedownload/:hash'
}

const overlayPaths = {
	CART: '/cart/:section?/:order_id?',
	CONTACT: '/contact-form'
}

let ContactForm;
let Cart;

if(!helpers.isServer){ 
	ContactForm = require('./components/contact-form').default;
	Cart = require('./components/cart').default;
}

class Routes extends Component {

	constructor(props) {
		
		super(props);

		this.currentHashPurl = '';
		
		this.checkForOverlayPath(this.props.location);
		this.hashPurlLoadsInProgress = [];
		this.contentLoadsInProgress = [];
		this.currentAnchorScrollAttempt = 0;
		this.fixedPinScrollOffset = 0;

		if(!helpers.isServer){ 

			// prevent anchor scrolling on a double click
			window.addEventListener('click', e => {

				let target = e.target;

				// if we clicked on an element with a shadowroot, check for links inside the shadowroot
				// linked media items work by submerging their link inside the shadow dom
				if( target.shadowRoot ){
					target = e.composedPath().find(node=>node.tagName==='A') || target;
				} else if(target.closest('a')) {
					target = target.closest('a');
				}

				if(
					target.nodeName === "A" 
					&& target.hash 
					&& target.hash === window.location.hash
				) {
					// this prevents a double click from triggering a browser scroll
					e.preventDefault();

					this.runAnchorScroll(
						this.getAnchorForHash(target.hash.substring(1).toLowerCase())
					);

				}

			});

		}

	}

	shouldComponentUpdate(nextProps) {

		if (this.props.location !== nextProps.location) {
			this.checkForOverlayPath(nextProps.location);
		}

		return true;

	}

	checkForOverlayPath = (nextLocation) => {

		for( const key in overlayPaths ) {

			const path = overlayPaths[key]

			const match = matchPath(nextLocation.pathname, {
				path
			})

			if(match) {

				if(_.isEqual(match, this.lastMatch)) {
					return;
				}

				this.lastMatch = match;

				// lock the underlay location
				if(!this.lockedUnderlayLocation) {
					this.lockedUnderlayLocation = {...this.props.location};

					// if the locked location is our overlay url, load root
					if(this.lockedUnderlayLocation.pathname === match.url) { 
						this.lockedUnderlayLocation.pathname = '/'
					}

				}

				// bail
				return;

			} else {
				
				delete this.lastMatch;

			}

		}

		// if we made it here it means there's no overlay active.
		// If we're still locking the underlay, unlock it and ensure
		// remaining overlays are closed
		if(this.lockedUnderlayLocation) {

			this.lockedUnderlayLocation = null;

		}

	}

	getAnchorForHash = purl => {

		let anchor = null;

		try {
			anchor = document.querySelector(`a#${purl}`);
		} catch(e) {
			// silently fail if the generated querySelector is not valid
			// and use the fallback
		}

		if(!anchor) {

			const fallback = document.getElementById(purl);

			// make sure it's actually a link
			if(fallback && fallback.nodeName === "A") {
				anchor = fallback;
			}

		}

		return anchor;

	}

	runAnchorScroll = (anchor, options = {}) => {

		if(!anchor) {
			throw 'Unable to scroll to anchor';
			return;
		}

		// set a snap scroll distance if the target is far enough awway
		const windowHeight = document.documentElement.clientHeight;
		const closestScrollParent = anchor.closest('.fixed.page.allow-scroll') || document.scrollingElement;
		const scrollHeight = closestScrollParent.scrollHeight;
		const scrollTop = closestScrollParent.scrollTop;
		const anchorRect = anchor.getBoundingClientRect();

		const availableHeight = (scrollHeight-windowHeight) - (scrollTop+anchorRect.top);

		// only add a snapscrollDistance/offset if one doesn't already exist
		if( !options.snapScrollDistance ){
			if( anchorRect.top > windowHeight*1.5 ) {
				options.snapScrollDistance = -200
			} else if ( anchorRect.top < -windowHeight*1.5 ){
				options.snapScrollDistance = 200
			}
		}

		// if we don't have enough runway to do an offset, just ease straight to the anchor
		if( availableHeight < 200){
			delete options.snapScrollDistance;
		}


		const requestScrollOptions = {
			transitionTime: 300,
			callback: null,
			verticalAlign: 'top',
			windowAlign: 'top',
			easing: 'easeOutExponential',
			offset: {
				x:0,
				y:this.fixedPinScrollOffset,
			},			
			preventQuickViewClose:false,
			lazyScroll:false,
		};

		if(options.hasOwnProperty('snapScrollDistance')) {

			requestScrollOptions.offset.y = options.snapScrollDistance + this.fixedPinScrollOffset;

			requestScrollOptions.easing = 'linear';
			requestScrollOptions.transitionTime = 0;


			requestScrollOptions.callback = ()=>{

				requestAnimationFrame(()=>{

					anchor.dispatchEvent(new CustomEvent('request-scroll', {
						bubbles: true,
						cancelable: true,
						composed: true,
						detail: {
							...requestScrollOptions,
							offset: {
								x: 0,
								y: this.fixedPinScrollOffset,
							},
							callback: null,
							easing: 'easeOutExponential',
							transitionTime: 350,
						},
					}));					
				})

			}			

		}

 		requestAnimationFrame(() => {
 			requestAnimationFrame(() => {		
				anchor.dispatchEvent(new CustomEvent('request-scroll', {
					bubbles: true,
					cancelable: true,
					composed: true,
					detail: requestScrollOptions,
				}));
			});
 		});

	}

	// this will keep rendering old content until new content is available.
	// It's a flawed workaround but will work until react suspense is available.
	deferredContentRenderer(newPageId, idType) {

		// hash PURls should be loaded including a few pages above and below them (if possible).
		// Then we render them in one go and trigger a scroll if smooth scrolling is enabled
		const hashPurl = (this.lockedUnderlayLocation || this.props.location).hash.substring(1).toLowerCase();

		// if we directly linked to an overlaid page, we want to render it in the context if it's 
		// parent set.
		const state = this.props.store.getState();
		const content = idType === 'purl' ?
			selectors.getContentByPurl(state, newPageId) 
			: selectors.getContentById(state, newPageId);

		// When direct linking to an overlaid page, render the overlay
		// in the context of the set it's part of
		if(!this.currentId && content?.overlay) {

			newPageId = helpers.getCorrectRenderContextForGivenPID(state, selectors, content.id);
			idType = 'pid';

		}

		// Ignore this on the server
		if(helpers.isServer){
			return <Content id={newPageId} idType={idType} />;
		}

		// use this to keep track of what pageID was last requested in case 
		// we get concurrent async requests.
		this.lastRequestedId = newPageId;
		this.lastRequestedIdType = idType;
		this.lastRequestedHashPurl = hashPurl;

		if(!this.currentId) {

			// if we're not currently rendering anything, just render the 
			// content as normal.
			this.currentId = this.lastRequestedId;
			this.currentIdType = this.lastRequestedIdType;

		} else if(
			this.currentId !== this.lastRequestedId
			|| this.currentIdType !== this.lastRequestedIdType
		) {

			const loadId = this.lastRequestedId + '/' + this.lastRequestedIdType;

			if(!this.contentLoadsInProgress.includes(loadId)) {

				this.contentLoadsInProgress.push(loadId);

				window.__deferred_page_load_promise__ = new Promise(resolve => {

					// we are rendering something new, but something else has rendered before.
					// run a content fetch and keep rendering the old content
					((deferredPageID, deferredIdType) => {
						
						this.props.fetchContent(deferredPageID, {
							idType: deferredIdType
						})
						.catch(() => {})
						.finally(() => {

							this.contentLoadsInProgress = this.contentLoadsInProgress.filter(value => value !== loadId);

							// we have the new content (or not). Make sure it's our most up to date request and render it. 
							if(
								this.lastRequestedId === deferredPageID
								&& this.lastRequestedIdType === deferredIdType
							) {
								this.currentId = deferredPageID;
								this.currentIdType = deferredIdType;
								this.forceUpdate();
							}

							delete window.__deferred_page_load_promise__;
							resolve();

						});

					})(this.lastRequestedId, this.lastRequestedIdType);

				});

			}

			// continue and render the old content again while loading
		}

		// handle hash changes
		if(
			this.lastRequestedHashPurl
			&& this.currentHashPurl !== this.lastRequestedHashPurl
		) {

			(deferredHashPurl => {

				if(this.hashPurlLoadsInProgress.includes(deferredHashPurl)) {
					return;
				}

				this.hashPurlLoadsInProgress.push(deferredHashPurl);

				// try finding the hash on the page, but when we have already directly loaded the targeted
				// page we want to start rendering it in it's scrollable parent set context
				if(content?.purl !== deferredHashPurl) {

					try {

						// if the anchor is already on the page, scroll to it immediately
						const anchor = this.getAnchorForHash(deferredHashPurl);

						if(anchor) {

							// update currently used hash
							this.currentHashPurl = deferredHashPurl;

							// render using the new hash 
							this.forceUpdate(() => {

								this.hashPurlLoadsInProgress = this.hashPurlLoadsInProgress.filter(value => value !== deferredHashPurl);

								this.runAnchorScroll(
									this.getAnchorForHash(deferredHashPurl)
								);

							});

							return;

						}

					} catch(e) {
						console.error(e);
						this.hashPurlLoadsInProgress = this.hashPurlLoadsInProgress.filter(value => value !== deferredHashPurl);
					}

				}

				// first we load the page belonging to the hash PURL.
				this.props.fetchContent(deferredHashPurl, {
					idType: 'purl'
				}).finally(() => {

					this.hashPurlLoadsInProgress = this.hashPurlLoadsInProgress.filter(value => value !== deferredHashPurl);

					if(this.lastRequestedHashPurl !== deferredHashPurl) {
						// another hash PURL has been requested while we were fetching data
						return;
					}

					const state = this.props.store.getState();
					const page = selectors.getContentByPurl(state, deferredHashPurl);

					if(
						// no page to scroll to
						!page 
						// or the linked content is not a page
						|| page.page_type !== "page"
						// or the page is pinned
						|| page.pin
					) {

						console.log('No valid target found. Just scroll to an anchor if it exists');

						// update currently used hash
						this.currentHashPurl = deferredHashPurl;

						// no content exists for this PURL, just fall back to emulating native anchor link behavior
						try {
							this.getAnchorForHash(deferredHashPurl)?.scrollIntoView()
						} catch(e) {
							console.error(e);
						}

						return;

					}

					// we have content, verify it's renderable as part of the current stack. If not,
					// we'll have to direct link it so that the link doesn't appear broken
					if(
						!content
						// or the currently rendered set is not a stack
						|| content.stack !== true
						// or if the page is not a child of the currently rendered set
						|| selectors.getParentSetList(state, page.id).includes(content.id) !== true
					) {

						if(this.props.adminMode) {
							
							// update currently used hash
							this.currentHashPurl = deferredHashPurl;

							// render the new hash
							this.forceUpdate();

							// do not mess with the URL in admin mode
							return;

						}

						const parentSets = selectors.getParentSetList(state, page.id).map(id => state.sets.byId[id]);

						// grab closest stacked parent
						const firstStackedParent = parentSets.find(set => set.stack);

						// try to render the parent stack in the correct context
						if(firstStackedParent) {

							// Important: do not update currently used hash here, it'll happen after the redirect
							this.props.history.replace(`/${firstStackedParent.id === 'root' ? '' : firstStackedParent.purl}#${deferredHashPurl}`);

							// scroll to top instantly like a normal navigation
							document.scrollingElement.scrollTo({
								top: 0,
								left: 0,
								// prevent scroll-behavior: smooth
								behavior: 'instant'
							});

						} else {

							// clear currently used hash
							this.currentHashPurl = '';

							// replace hash with direct url
							this.props.history.replace(`/${deferredHashPurl}`);

							// scroll to top instantly like a normal navigation
							document.scrollingElement.scrollTo({
								top: 0,
								left: 0,
								// prevent scroll-behavior: smooth
								behavior: 'instant'
							});

						}

						return;
					
					}

					const missingContent = [];
					const parentSet = state.sets.byId[selectors.getItemParentId(state, page.id)];

					// we are good to render, just load the surrounding pages so we can scroll up or down
					let limit = 5;

					treeWalker(state.structure, state.pages, state.sets, parentSet, state.structure.indexById[page.id] - 1, {
						reverse: true,
						traverseUp: this.props.isFeed ? true : false,
						callback: (parent, index, content) => {
							if(!content) {
								missingContent.push({parent, index});
							}
							
							if(limit-- === 0) {
								return 'break';
							}
						}
					});

					limit = 5;

					treeWalker(state.structure, state.pages, state.sets, parentSet, state.structure.indexById[page.id] + 1, {
						reverse: false,
						traverseUp: this.props.isFeed ? true : false,
						callback: (parent, index, content) => {
							if(!content) {
								missingContent.push({parent, index});
							}

							if(limit-- === 0) {
								return 'break';
							}
						}
					});

					// group our results by sets
					const promises = _.map(_.groupBy(missingContent, 'parent'), (items, setId) => {
						return this.props.fetchContent(setId, {
							indexes: _.map(items, 'index'),
							idType: 'pid'
						})
					});

					Promise.all(promises).finally(() => {

						if(this.lastRequestedHashPurl !== deferredHashPurl) {
							// another hash PURL has been requested while we were fetching data
							return;
						}

						// update currently used hash
						this.currentHashPurl = deferredHashPurl;

						const state = this.props.store.getState();
						const firstRenderedPage = document.querySelector('body > .content > .pages .page');

						let anchorIsAbove = false;

						if(firstRenderedPage && state.structure.bySort[page.id] < state.structure.bySort[firstRenderedPage.id]) {
							anchorIsAbove = true;
						}

						// render
						this.forceUpdate(() => {

							if(this.lastRequestedHashPurl !== deferredHashPurl) {
								// another hash PURL has been requested while we were rendering
								return;
							}

							const anchor = this.getAnchorForHash(deferredHashPurl);

							if(anchor) {

								this.runAnchorScroll(anchor, {
									snapScrollDistance: anchorIsAbove ? 200 : -200
								});

							}

						});
					});


				});

			})(this.lastRequestedHashPurl);

		} else if(!this.lastRequestedHashPurl) {

			// clear hash PURL
			this.currentHashPurl = '';
		
		}

		return (
			<PinManager>
				<PinManagerContext.Consumer>
					{ context => {
						this.fixedPinScrollOffset = (context.totalTopFixedPinsHeight || 0) * -1;
					}}
				</PinManagerContext.Consumer>
				<Content 
					id={this.currentId} 
					idType={this.currentIdType} 
					hashPurl={this.currentHashPurl}
				/>
			</PinManager>
		)
	}

	setHomepageClass = () => {

		if (this.props.location?.pathname === '/' || this.props.location?.pathname.includes( this.props.homepageId ) ) {
			document.querySelector('body').classList.add('home')
		} else {
			document.querySelector('body').classList.remove('home')
		}

	}

	componentDidUpdate = () => {
		this.setHomepageClass();
	}

	componentDidMount = () => {
		this.setHomepageClass();
	}

	render() {

		if(this.props.adminMode && this.props.adminInitialized === false) {
			// don't attempt to do any routing before the admin has initialized. This
			// is because we don't want to render content before the CRDT has been loaded
			// and run the chance of rendering stale live data.
			return null;
		} 

		if(!this.props.hasSite) {
			return null;
		}

		let overlay = null;
		let overlayLocation = null;
		let underlayLocation = this.props.location;

		if(this.lockedUnderlayLocation) {

			underlayLocation = this.lockedUnderlayLocation;
			overlayLocation = this.props.location;

			overlay = <div class="overlay">
				<Switch location={overlayLocation}>
					
					<Route exact path={overlayPaths.CART} render={({match})=> {
						return <Cart closeOverlay={(options = {}) => {
							this.props.history[options.replaceHistory ? 'replace' : 'push'](this.lockedUnderlayLocation.pathname + this.lockedUnderlayLocation.hash, {preventScrollReset: true})
						}}/>;
					}}/>

					<Route exact path={overlayPaths.CONTACT} render={({match})=> {
						return <ContactForm closeOverlay={() => {
							this.props.history.push(this.lockedUnderlayLocation.pathname + this.lockedUnderlayLocation.hash, {preventScrollReset: true})
						}} />;
					}}/>

				</Switch>
			</div>

		}

		return(
			<>

				{overlay}

				<Switch location={underlayLocation}>
					<Route exact path={paths.ROOT_PATH} render={({match})=> {

						if(this.props.isFeed) {
							return this.deferredContentRenderer('root', 'pid');
						}

						return this.deferredContentRenderer(this.props.homepageId || 'root', 'pid');
					}}/>

					<Route exact path={paths.DIGITAL_PRODUCT_DOWNLOAD} render={({match})=> {
						return <DigitalProductDownloadForm hash={match.params.hash} />
					}}/>

					<Route exact path={paths.IMG_PATH} render={({match})=> {
						return <SiteImages />
					}}/>

					<Route exact path={paths.SITE_PREVIEW_PROXY_PATH} render={({match})=> {

						if(this.props.isFeed) {
							return this.deferredContentRenderer('root', 'pid');
						}

						return this.deferredContentRenderer(this.props.homepageId || 'root', 'pid');
					}}/>

					<Route exact path={paths.ADMIN_CLIENT_SIDE_RENDERING_PATH} render={({match})=> {
						// Avoid a flash of content when loading admin edit links directly
						return null;
					}}/>

					<Route exact path={paths.PAGE_PATH} render={({match})=> {
						return this.deferredContentRenderer(match.params.page.toLowerCase(), 'purl');
					}}/>

					<Route exact path={paths.PAGE_BY_ID_PATH} render={({match})=> {
						return this.deferredContentRenderer(match.params.pid, 'pid');
					}}/>

					<Route path="*" render={({location}) => {
						return this.deferredContentRenderer(location.pathname.substring(1), 'purl');
					}} />
				</Switch>
			</>
		)

	}

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchContent: actions.fetchContent,
		updateFrontendState: actions.updateFrontendState
	}, dispatch);

}


export default withStore(withRouter(connect(
	(state, ownProps) => {

		return {
			hasSite: !!state.site,
			homepageId: selectors.getHomepageId(state),
			isFeed: state.sets.byId.root.stack,
			adminInitialized: state.adminState?.initialized || false,
			adminMode: state.frontendState.adminMode
		};
	}, 
	mapDispatchToProps
)(Routes)));
