import { Component , cloneElement, toChildArray, createRef} from "preact";
import {createPortal} from "preact/compat";
import { connect } from 'react-redux';
import * as helpers from "@cargo/common/helpers";
import ResizeCard from "./gallery/resize-card";
import windowInfo from "@cargo/common/window-info"

import { generateColumnMap } from "./gallery/layout-helpers";

import _ from 'lodash';
import register from "./register"

import { withPageInfo } from "./page-info-context";
import {UsesHost} from "./uses/uses"

import ColumnEditor from '../overlay/column-editor';

/* About columns

There are two systems of 'columns' at work:

1. columnUnits
	These are the actual html containers in which content is placed.
	There should always be at least one.
	A column set may have up to as many columnUnits as it has layoutColumns.
	Each columnUnit has a span attribute that determines how many layoutColumns it occupies.

2. layoutColumns
	These are the 'grid' components which create the design system in which columnUnits are placed.
	The default value for column sets is layoutColumns = 12.
	An individual columnUnit may span across multiple layoutColumns.
	For example, if I have 3 columnUnits and 12 layoutColumns, I can arrange the columnUnits like so:
	4-4-4
	or
	3-6-3
	or
	2-4-6
	etc.

By default the columns will display the same in mobile as in desktop. There are two additional 
options to change how this work.

1. hide empty columns
	columnUnits that have been determined to be empty are hidden.
	The layoutColumns that each empty columnUnit would normally occupy are subtracted from the total.

	For example, given the following layout:
	a. layoutColumns = 12
	b. there are three columnUnits arranged as 3-6-3
	c. the third columnUnit is empty

	In a mobile layout, this layout becomes:
	a. layoutColumns = 9
	b. Two columnUnits are arranged as 3-6
	c. The third column is hidden
	
2. stack
	columnUnits that are determined to be empty are hidden, as in (1).
	All other columnUnits are displayed vertically, leftmost column first
*/

class ColumnSet extends Component {
	constructor(props){
		super(props);
		
		this.state = {
			mobileActive: windowInfo.data.mobile.active,
			elWidth: 0,
			gutterWidth: 0,
			hasSizes: false,
			incrementLayout: 0,
		}

		this.props.baseNode.render = ()=>{
			this.setState({
				incrementLayout: this.state.incrementLayout++
			})
		}
	}

	render(props,state){
		const {
			adminMode,
			baseNode,
			pageInfo,
		    'mobile-stack': mobileStack,
		    'mobile-hide-empty': mobileHideEmpty,
		    'mobile-gutter': mobileGutter,
		    units,
		} = props;

		const {
			gutterWidth,
			mobileActive,
			hasSizes,
		} = state;

		let {
			elWidth
		} = state;

		if( !hasSizes ){
			elWidth = this.props.baseNode.offsetWidth;
		}

		let gutterSizeArray = [];
		let gutterSizeCSS ='';
		if( mobileActive ){

			// if there isn't a mobile gutter defined...
			if( mobileGutter === undefined){

				// take the active desktop gutter size
				gutterSizeArray = helpers.getCSSValueAndUnit(props.gutter, 'rem');

				// gutter="calc( 10px + vh )" is perfectly usable inside calc once we remove the calc string
				// 'calc() and var() values show up in the 'units' column of the array
				if( gutterSizeArray[1].indexOf('calc(') > -1 ){
					gutterSizeCSS = gutterSizeArray[1].replace('calc(', '(')
				} else {
					gutterSizeCSS = gutterSizeArray.join('')					
				}

				// and multiply it by our magic mobile padding offset number
				gutterSizeCSS = 'calc( var(--mobile-padding-offset, 1) * ' + gutterSizeCSS + ' )';

			} else {

				gutterSizeArray = helpers.getCSSValueAndUnit(mobileGutter, 'rem');
				gutterSizeCSS = gutterSizeArray.join('')

				// if we have a mobile setting active and it is in rems, apply calc
				if ( gutterSizeCSS.includes('rem') ) {
					gutterSizeCSS = 'calc( var(--mobile-padding-offset, 1) * ' + gutterSizeCSS + ' )';
				}
			}

		} else {

			// otherwise, we're in desktop mode and the gutter is always defined
			gutterSizeArray = helpers.getCSSValueAndUnit(props.gutter, 'rem');
			gutterSizeCSS = gutterSizeArray.join('')
		}

		const columnUnits = Array.from(baseNode.children).filter(el=>el.nodeName === 'COLUMN-UNIT' && !el.hasAttribute('data-discarded-column'));
		const discardColumns = Array.from(baseNode.children).filter(el=>el.nodeName ==='COLUMN-UNIT' && el.hasAttribute('data-discarded-column'));

		discardColumns.forEach((el)=>{
			el.remove();
		})		
		const emptyColumnUnits = columnUnits.map((col)=>{
			return !Array.from(col.childNodes).some(node=>{
				return node.nodeType === Node.ELEMENT_NODE || (node.nodeType ===Node.TEXT_NODE && node.nodeValue.trim().length > 0)
			})
		})
		let columnSpans = columnUnits.map(col=>parseInt(col.getAttribute('span')) || undefined);

		let removedUnits = 0;

		// 0 width spans are set to hide
		let displaySpans = columnSpans.map((span, index)=>{

			if ( mobileActive && emptyColumnUnits[index] && (mobileHideEmpty || mobileStack)){
				removedUnits = removedUnits + (parseInt(span) || 1);
				span = 'hide';
			} else {
				span = (span || 'uniform' );
			}

			return span
		});

		// if all spans are uniform or set to hide, then we use the uniform column map to do our layout
		let columnsAreUniform = displaySpans.filter(span=> span=='uniform' || span =='hide').length == displaySpans.length;

		// if there is no column amount set, then the columns are max(12, number of columnUnits)
		let layoutColumns = Math.max(columnUnits.length, this.props.units);

		if( columnsAreUniform){
			removedUnits = displaySpans.filter(span=> span=='hide').length
		}

		layoutColumns = layoutColumns - removedUnits;

		let	gutterPixelWidth = gutterWidth;


		// if no column has a span set, the 'columns' prop is ignored
		let columnStyles = '';
		if( mobileActive && mobileStack){
			columnStyles = `::slotted(column-unit){
				--column-width: ${elWidth}px;
				--resize-parent-width: ${elWidth}px;

			}`

			let lastDisplayedColumnIndex = _.findLastIndex(displaySpans, span=> span !== 'hide');

			// also hide empty spans in stack-column
			displaySpans.forEach((span,index)=>{
				if(span == 'hide'){
					columnStyles+= `
					::slotted(column-unit[slot="${index}"]) {
						display: none;
					}`	
				} else if (index < lastDisplayedColumnIndex) {
					columnStyles+= `
					::slotted(column-unit[slot="${index}"]) {
						margin-bottom: ${gutterWidth}px;
					}`	
				}
			})

		} else {

			let columnMapObj = generateColumnMap(columnsAreUniform ? columnUnits.length - removedUnits : layoutColumns, gutterWidth, elWidth);
			let columnAndGutterMap = columnMapObj.columnAndGutterMap;
			gutterPixelWidth = columnMapObj.gutterPixelWidth;
			let columnWidth = columnMapObj.columnWidth;

			let spanAccumulator = 0;
			columnStyles = displaySpans.map((span, index)=>{
				let width;

				switch(span){
					case "uniform":

						width = columnAndGutterMap[spanAccumulator]?.right - columnAndGutterMap[spanAccumulator]?.left || 10;
						spanAccumulator+=1;
						return `::slotted(column-unit[slot="${index}"]) {
							--column-width: ${width}px;
							--resize-parent-width: ${width}px;
						}`

					case "hide":
						return `::slotted(column-unit[slot="${index}"]) {
							display: none;
						}`

					default:
						width = columnAndGutterMap[spanAccumulator+(span+-1)]?.right - columnAndGutterMap[spanAccumulator]?.left || 10;
						spanAccumulator+=span;


						return `::slotted(column-unit[slot="${index}"]) {
							${width < 0 ? `
								margin-right: ${width+gutterPixelWidth}px;
								--column-width: 0px;
								--resize-parent-width: 0px;
							`:`
								--column-width: ${width}px;
								--resize-parent-width: ${width}px;							
							`}

						}`
				}


			}).join('\n				');			
		}

		columnUnits.forEach((col, i)=>{
			col.setAttribute('slot', i);
		})


		return createPortal(<><UsesHost
			adminMode={adminMode}
			baseNode={baseNode}
			customElementMode={true}
			scrollContext={this.props.scrollContext}
			>
					
					<style>{`
						:host{
							visibility: ${hasSizes ? 'visible': 'hidden'};
			 				--columns-gutter: ${gutterPixelWidth}px;
							margin-top: calc(var(--gutter-expand, 0) * ${gutterPixelWidth}px);
		
							width: 100%;
							position: relative;
							contain: layout;
							clear: both;							

							${
								mobileActive && mobileStack ? `
									display: flex;
									align-items: start;
									justify-content: flex-start;
									flex-direction: column;
									flex-wrap: nowrap;
									height: auto;
								`: `
									display: flex;
								    align-items: stretch;
								    justify-content: flex-start;							
									flex-direction: row;
									flex-wrap: nowrap;							
								`
							}
						}
		
					`}</style>
					<style>{`
		
						::slotted(column-unit:not(:last-child)) {
						    margin-right: ${mobileActive && mobileStack ? 0 : gutterPixelWidth+'px'};
						}
		
						::slotted(column-unit) {
							display: block;
							word-wrap: break-word;
							position: relative;
						    max-width: 100%;   
						    flex-grow: 0;
						    flex-shrink: 0;
						    flex-basis: ${mobileActive && mobileStack ? 'auto' : 'var(--column-width)'};
						    width: var(--column-width);
						}
		
						::slotted(column-unit:last-child) {
						    flex-grow: 1;
						}
		
						${columnStyles}				
					`}</style>
					<ResizeCard
						values={{
							elWidth: '100%',
							gutterWidth: gutterSizeCSS
						}}
						style={{
							position: 'absolute',
							inset: '0 auto',
						}}
						onResize={this.onResize}
					/>
					
					{columnUnits.map((attrib, i)=><slot name={i}/>)}
					
				</UsesHost>
				{adminMode && pageInfo.isEditing && !helpers.isServer && <ColumnEditor
						elWidth={elWidth}
						gutterWidth={gutterWidth}
						emptyColumns={emptyColumnUnits}
						isMobile={mobileActive}
						mobileStack={mobileStack}
						mobileHideEmpty={mobileHideEmpty}
						layoutColumns={layoutColumns}
						units={units}
						columnSpans={columnSpans}
						unitsToRemove={removedUnits}
						displaySpans={displaySpans}
						gutter={gutterSizeCSS}
						columnInstance={baseNode}
					/>
				}				
				</>, this.props.baseNode.shadowRoot)
	}

	onResize = (key, size) =>{

		this.setState((prevState)=>{


			const newState = {...prevState};
			newState[key] = size;
			newState.hasSizes = true;


			return newState
		})
	}

	onMobileChange = (mobileActive) => {
		this.setState({mobileActive})
	}



	componentDidMount(){

		windowInfo.on('mobile-change', this.onMobileChange)

	}


	componentWillUnmount(){
		windowInfo.off('mobile-change', this.onMobileChange)
	}

}

ColumnSet.defaultProps = {
	units: 12,
	gutter: '1rem',
	'mobile-stack': true,
	'mobile-hide-empty': false,
	'mobile-gutter': undefined
}

const ConnectedColumnSet = withPageInfo(connect(
    (state, ownProps) => {
        return {
            adminMode: state.frontendState.adminMode
        };
    }
)(ColumnSet));


register(ConnectedColumnSet, 'column-set', [
	'units',
	'gutter',
	'mobile-gutter',
	'mobile-stack',
	'mobile-hide-empty'
]) 


export default ConnectedColumnSet


